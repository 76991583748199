import styled from 'styled-components'
import { mediaMax } from '../../../utils/breakpoints'

export const Title = styled.h1`
  font-weight: 300;
  color:#fff;
  z-index:1;
  font-size:max(24px, 4.167vw);
  width: 70vw;
  font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
  line-height:1.2;

  @media ${mediaMax.tablet}{
    padding-top:50px;
    width:80%;
  }
`

export const Abstract = styled.p`
  color:${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.fontFamilySecondary};

	font-size: max(16px, 1.45833vw);
  line-height: 148%;

  margin-top:40px;
  width: 47vw;

  @media ${mediaMax.tablet}{
    width: 100%;
  }
`

export const Description = styled.p`
  color:${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.fontFamilySecondary};

  font-size:max(18px, 1.25vw);
	line-height: 148%;
`